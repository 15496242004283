export function isEqual<T>(a: T, b: T): boolean {
  if (typeof a !== typeof b) {
    return false
  }

  if (
    typeof a !== 'object' ||
    typeof b !== 'object' ||
    a === null ||
    b === null
  ) {
    return a === b
  }

  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)

  if (aKeys.length !== bKeys.length) {
    return false
  }

  for (const key of aKeys) {
    if (!(key in b)) {
      return false
    }
    if (!isEqual(a[key as keyof T], b[key as keyof T])) {
      return false
    }
  }

  return true
}
